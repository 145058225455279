<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button type="button" @click="goBack()" class="btn btn-inverse" title="Kembali">Kembali</button>
        </span>
      </h3>
      <hr>
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div class="col-md-6 pull-left">
            <small class="text-muted">Nama</small>
            <h6>{{state.detail.name}}</h6>
            <small class="text-muted">OPD/SKPD</small>
            <h6>{{state.detail.data_opd}}</h6>
            <small class="text-muted p-t-30 db">Kapasitas</small>
            <h6>{{state.detail.capacity}}</h6>
            <small class="text-muted p-t-30 db">Fasilitas</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
export default {
  components: {
    RotateSquare5,
    ImageItem
  },
  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.rooms;
    }
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false
      };
      this.$store.commit("rooms/STATE", state);
      this.$store.dispatch("rooms/getRoomsById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("rooms/onCancel");
    },
  }
};
</script>
